import { Modal } from 'react-responsive-modal';
import cn from 'classnames';
// Ui
import Icon, { IconNames } from 'ui/Icon/Icon';
// Ui2
import Button from 'ui2/Button/Button';
// Styles
import 'react-responsive-modal/styles.css';
import styles from './Notification.module.scss';

type NotificationProps = {
  open: boolean;
  onCancel: () => void;
  title: string;
  description?: string | JSX.Element;
  cancelText: string;
  okText: string;
  onOk: () => void;
  icon?: IconNames;
  iconColor?: 'green';
};

const Notification = ({
  open,
  onCancel,
  title,
  description,
  cancelText,
  okText,
  onOk,
  icon = 'star',
  iconColor,
}: NotificationProps): JSX.Element => {
  return (
    <Modal
      open={open}
      classNames={{
        overlay: styles.overlay,
        modal: styles.modal,
      }}
      onClose={onCancel}
      showCloseIcon={false}
      center
    >
      <div className={styles.modalContent}>
        <div className={styles.contentWrapper}>
          <Icon
            name={icon}
            className={cn(styles.icon, {
              [styles[`color-${iconColor}`]]: iconColor,
            })}
          />
          <p className={styles.title}>{title}</p>
          <p className={styles.description}>{description}</p>
        </div>

        <button
          className={styles.closeButton}
          onClick={onCancel}
          aria-label="Close"
        >
          <Icon name="close-new" className={styles.closeIcon} />
        </button>

        <div className={styles.actions}>
          <Button
            className={styles.button}
            color="transparent"
            onClick={onCancel}
          >
            {cancelText}
          </Button>
          <Button className={styles.button} color="harvest-gold" onClick={onOk}>
            {okText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Notification;
