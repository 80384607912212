import { useEffect } from 'react';
import cn from 'classnames';
// Types
import { MyPaymentMethods_myPaymentMethods } from 'api/payments/types/MyPaymentMethods';
// Components
import { Card } from './Card/Card';
// Styles
import styles from './PaymentCards.module.scss';

type PaymentCardsProps = {
  data: MyPaymentMethods_myPaymentMethods[];
  onPaymentSelect?: (methodId: string) => void;
  theme?: Theme;
};

const PaymentCards = ({
  data,
  onPaymentSelect,
  theme = 'white',
}: PaymentCardsProps) => {
  useEffect(() => {
    if (data?.length) {
      const defaultPaymentMethodId =
        data?.find((i) => i?.isDefault)?.methodId || '';

      if (onPaymentSelect) {
        onPaymentSelect(defaultPaymentMethodId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ul
      className={cn(styles.paymentCardsWrapper, {
        [styles[`theme-${theme}`]]: theme,
      })}
    >
      {data?.map((payment) => (
        <li key={payment.id} className={styles.paymentCardItem}>
          <Card theme={theme} payment={payment} onSelect={onPaymentSelect} />
        </li>
      ))}
    </ul>
  );
};

export default PaymentCards;
