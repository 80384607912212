import { useQuery, QueryResult } from '@apollo/client';
// Api
import { MY_PAYMENT_METHODS } from 'api/payments/queries';
// Types
import { MyPaymentMethods } from 'api/payments/types/MyPaymentMethods';
// Helpers
import { useAppContext } from './useAppContext';

export const useGetMyPaymentMethods = (): QueryResult<MyPaymentMethods> => {
  const { token } = useAppContext();

  const data = useQuery<MyPaymentMethods>(MY_PAYMENT_METHODS, {
    fetchPolicy: 'cache-and-network',
    skip: !token?.accessToken,
  });

  return data;
};
