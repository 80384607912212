import { gql } from '@apollo/client';
// Fragments
import { PaymentFields } from './fragments';

export const ADD_STRIPE_PAYMENT_METHOD = gql`
  mutation AddStripePaymentMethod($id: String!) {
    addStripePaymentMethod(id: $id) {
      ...PaymentFields
    }
  }
  ${PaymentFields}
`;

export const SET_PAYMENT_METHOD_AS_DEFAULT = gql`
  mutation SetPaymentMethodAsDefault($id: String!) {
    setPaymentMethodAsDefault(id: $id) {
      ...PaymentFields
    }
  }
  ${PaymentFields}
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($id: String!) {
    removePaymentMethod(id: $id) {
      id
    }
  }
`;
