import { gql } from '@apollo/client';
// Fragments
import { PaymentFields } from './fragments';

export const MY_PAYMENT_METHODS = gql`
  query MyPaymentMethods {
    myPaymentMethods {
      ...PaymentFields
    }
  }
  ${PaymentFields}
`;
