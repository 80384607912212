import { gql } from '@apollo/client';

export const PaymentFields = gql`
  fragment PaymentFields on PaymentMethod {
    id
    type
    last4
    expMonth
    expYear
    methodId
    isDefault
    cardholderName
  }
`;
