import { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
// Hooks
import { useGetCurrUser } from 'hooks';

type ConfirmCardPayment = {
  stripeIntentClientSecret: string;
  paymentMethodId: string;
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: string) => void;
};

type UseConfirmCardPayment = {
  confirmCardPayment: (props: ConfirmCardPayment) => Promise<void>;
  loading: boolean;
  error: string | null;
};

export const useConfirmCardPayment = (): UseConfirmCardPayment => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const stripe = useStripe();
  const { data: userData } = useGetCurrUser();

  const confirmCardPayment = async ({
    stripeIntentClientSecret,
    paymentMethodId,
    onSuccessCallback,
    onErrorCallback,
  }: ConfirmCardPayment) => {
    setLoading(true);
    setError(null);

    if (!stripe || !stripeIntentClientSecret) {
      setLoading(false);
      return;
    }

    const {
      error,
      paymentIntent: { status } = {},
    } = await stripe.confirmCardPayment(stripeIntentClientSecret, {
      payment_method: paymentMethodId,
      receipt_email: userData?.me?.email || '',
    });

    if (error?.message) {
      setError(error.message);

      if (onErrorCallback) {
        onErrorCallback(error.message);
      }
    } else {
      const isSuccessStatus =
        status === 'succeeded' || status === 'requires_capture';

      if (onSuccessCallback && isSuccessStatus) {
        try {
          onSuccessCallback();
        } catch (error) {
          console.log('onSuccessCallback error', error);
        }
      }
    }

    setLoading(false);
  };

  return { confirmCardPayment, loading: isLoading, error };
};
