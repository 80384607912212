import { useState } from 'react';
import QRCode from 'qrcode.react';
import cn from 'classnames';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Hooks
import { useGetSocialLinks } from 'hooks';
// Ui
import Icon from 'ui/Icon/Icon';
// Ui2
import Modal from 'ui2/Modal/Modal';
import Button from 'ui2/Button/Button';
// Components
import { showToast } from 'components/common/Toast/Toast';

import styles from './SocialShareQRButton.module.scss';

type SocialShareQRButtonProps = {
  isArticleButton?: boolean;
  modalTitle: string;
  modalText: string;
  path: string;
  twitterText: string;
  icon?: boolean;
  iconV2?: boolean;
  itemTitle?: string;
  smallButton?: boolean;
};

const SocialShareQRButton = ({
  isArticleButton = false,
  modalTitle,
  modalText,
  path,
  twitterText,
  icon = false,
  itemTitle,
  smallButton = false,
}: SocialShareQRButtonProps) => {
  const [isVisible, setVisibility] = useState<boolean>(false);

  const { facebookShareLink, twitterShareLink } = useGetSocialLinks({
    url: path,
    twitterText,
  });

  const sharePathname = `${getEnvLink()}${path}`;

  const handleModalOpen = () => setVisibility(true);

  const handleModalClose = () => setVisibility(false);

  const handleCopyLinkButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(sharePathname);

      showToast({
        message: 'Copied',
        autoClose: 1000,
      });
    } catch (error) {
      showToast({
        message: 'Could not copy text',
        type: 'error',
        autoClose: 1000,
      });
    }
  };

  const hasIcon = icon || isArticleButton;
  const buttonColor =
    smallButton && !isArticleButton ? 'transparent-gold' : 'white';

  return (
    <div>
      {hasIcon ? (
        <Button
          xs={smallButton ? 'xSmall' : 'default'}
          color={buttonColor}
          onClick={handleModalOpen}
          className={cn({
            [styles.shareButton]: !smallButton,
            [styles.smallButton]: smallButton,
            [styles.articleStyle]: isArticleButton,
          })}
        >
          {(!smallButton || isArticleButton) && (
            <Icon
              name={isArticleButton ? 'v2-share' : 'mobile-share'}
              className={styles.shareIcon}
            />
          )}
          Share
          {smallButton && icon && <Icon name="share" />}
        </Button>
      ) : (
        <button className={styles.shareProfileButton} onClick={handleModalOpen}>
          Share
        </button>
      )}

      <Modal
        title={modalTitle}
        subTitle={modalText}
        size="small"
        open={isVisible}
        onClose={handleModalClose}
        focusTrapped={false}
        theme="white"
      >
        <div className={styles.entry}>
          <div className={styles.qrWrapper}>
            <QRCode
              value={sharePathname}
              size={175}
              bgColor="#ffffff"
              fgColor="#000000"
              renderAs="svg"
              level="H"
              imageSettings={{
                src: '/images/qr-code/qr-code-logo.png',
                height: 40,
                width: 40,
                excavate: true,
              }}
            />
          </div>
          <span className={styles.tag}>{itemTitle}</span>
          <div className={styles.socialLinks}>
            <div className={styles.socialItem}>
              <a
                className={styles.socialButton}
                href={twitterShareLink}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="twitter"
              >
                <Icon name="twitter" />
              </a>
              <span className={styles.itemName}>Twitter</span>
            </div>
            <div className={styles.socialItem}>
              <a
                className={styles.socialButton}
                href={facebookShareLink}
                rel="noopener noreferrer"
                target="_blank"
                aria-label="facebook"
              >
                <Icon name="facebook" />
              </a>
              <span className={styles.itemName}>Facebook</span>
            </div>
            <div className={styles.socialItem}>
              <button
                className={styles.socialButton}
                onClick={handleCopyLinkButtonClick}
                aria-label="share-link"
              >
                <Icon name="link" withoutFill />
              </button>
              <span className={styles.itemName}>Copy Link</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SocialShareQRButton;
