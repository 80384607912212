import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
// Api
import {
  ADD_STRIPE_PAYMENT_METHOD,
  SET_PAYMENT_METHOD_AS_DEFAULT,
} from 'api/payments/mutations';
import { PaymentFields } from 'api/payments/fragments';
import { MY_PAYMENT_METHODS } from 'api/payments/queries';
// Helpers
import { yupStringRequiredValidation } from 'helpers/validation';
// Types
import {
  AddStripePaymentMethod,
  AddStripePaymentMethodVariables,
} from 'api/payments/types/AddStripePaymentMethod';
import {
  SetPaymentMethodAsDefault,
  SetPaymentMethodAsDefaultVariables,
} from 'api/payments/types/SetPaymentMethodAsDefault';
// Ui
import Modal from 'ui/Modal/Modal';
import RadioButton from 'ui/RadioButton/RadioButton';
import NotificationModal from 'ui/NotificationModal/NotificationModal';
// Ui2
import Button from 'ui2/Button/Button';
import TextField from 'ui2/TextField/TextField';
// Components
import { showToast } from 'components/common/Toast/Toast';
// Styles
import styles from './AddPaymentModal.module.scss';

type AddPaymentModalProps = {
  isOpen: boolean;
  userEmail: string;
  onClose: () => void;
};

type FormInputs = {
  cardHolderName: string;
  isDefault: boolean;
};

const getElementOptions = (placeholder?: string) => ({
  placeholder,
  style: {
    base: {
      color: '#000000',
      fontFamily:
        '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      '::placeholder': {
        color: '#999999',
      },
    },
    invalid: {
      color: '#e15555',
    },
  },
});

const validationSchema = yup.object().shape({
  cardHolderName: yupStringRequiredValidation('Cardholder name'),
  isDefault: yup.boolean(),
});

const AddPaymentModal: React.FC<AddPaymentModalProps> = ({
  isOpen,
  userEmail,
  onClose,
}) => {
  const [
    addStripePaymentMethod,
    { loading: addStripePaymentMethodLoading },
  ] = useMutation<AddStripePaymentMethod, AddStripePaymentMethodVariables>(
    ADD_STRIPE_PAYMENT_METHOD,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            myPaymentMethods(existingPaymentMethods = []) {
              const newPaymentMethodRef = cache.writeFragment({
                data: data?.addStripePaymentMethod,
                fragment: PaymentFields,
              });

              return [...existingPaymentMethods, newPaymentMethodRef];
            },
          },
        });
      },
      refetchQueries: [MY_PAYMENT_METHODS],
    }
  );

  const [
    setPaymentMethodAsDefault,
    { loading: setAsDefaultLoading },
  ] = useMutation<
    SetPaymentMethodAsDefault,
    SetPaymentMethodAsDefaultVariables
  >(SET_PAYMENT_METHOD_AS_DEFAULT, {
    refetchQueries: [MY_PAYMENT_METHODS],
  });

  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const [successModalIsVisible, setSuccessModalIsVisible] = useState<boolean>(
    false
  );
  const [stripeRequestIsLoading, setStripeRequestIsLoading] = useState<boolean>(
    false
  );

  useEffect(() => {
    setModalVisibility(isOpen);
  }, [isOpen]);

  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit, errors: formErrors } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cardHolderName: '',
      isDefault: true,
    },
  });

  const showError = (message: string) => {
    setStripeRequestIsLoading(false);
    showToast({
      message,
      type: 'error',
    });
  };

  const handleFormSubmit: SubmitHandler<FormInputs> = async (
    values
  ): Promise<void> => {
    const { cardHolderName, isDefault } = values;
    try {
      // When you pass one of the card elements in a split element form,
      // Stripe.js internally retrieves the value of the other elements.
      // https://github.com/stripe/react-stripe-js/issues/91
      const cardElement = elements?.getElement(CardNumberElement);

      if (stripe && cardElement) {
        setStripeRequestIsLoading(true);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: cardHolderName,
            email: userEmail,
          },
        });

        if (error) {
          return showError(error?.message || '');
        }

        if (paymentMethod?.id) {
          const { data } = await addStripePaymentMethod({
            variables: { id: paymentMethod?.id },
          });
          const id = data?.addStripePaymentMethod?.id;

          if (isDefault && id) {
            await setPaymentMethodAsDefault({
              variables: { id },
            });
          }

          setModalVisibility(false);
          setSuccessModalIsVisible(true);
        }

        setStripeRequestIsLoading(false);
      }
    } catch (error) {
      showError(
        error?.message ||
          'Looks like something went wrong. Double check all fields and try again'
      );
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setModalVisibility(false);
    setSuccessModalIsVisible(false);
  };

  return (
    <>
      <Modal
        title="Add card"
        size="small"
        open={isModalVisible}
        onClose={handleClose}
        focusTrapped={false}
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className={styles.addPaymentBody}>
            <TextField
              label="Card number"
              name="cardNumber"
              theme="white"
              type="text"
              customTextField={
                <CardNumberElement
                  id="cardNumber"
                  options={getElementOptions()}
                  className={styles.addPaymentElementInput}
                />
              }
            />

            <TextField
              name="cardHolderName"
              type="text"
              ref={register}
              placeholder="First Last name"
              error={formErrors?.cardHolderName?.message}
              label="Cardholder name"
            />

            <div className={styles.addPaymentElementsGroup}>
              <TextField
                label="Expire date"
                name="expireDate"
                theme="white"
                type="text"
                customTextField={
                  <CardExpiryElement
                    id="expireDate"
                    options={getElementOptions()}
                    className={styles.addPaymentElementInput}
                  />
                }
              />

              <TextField
                label="CVV"
                name="cvv"
                theme="white"
                type="text"
                customTextField={
                  <CardCvcElement
                    id="cvv"
                    options={getElementOptions()}
                    className={styles.addPaymentElementInput}
                  />
                }
              />
            </div>
          </div>

          <div className={styles.addPaymentFooter}>
            <RadioButton
              name="isDefault"
              ref={register}
              label="Make as default"
            />

            <Button
              type="submit"
              color="harvest-gold"
              xs="small"
              className={styles.addPaymentCta}
              loading={
                addStripePaymentMethodLoading ||
                stripeRequestIsLoading ||
                setAsDefaultLoading
              }
            >
              Add card
            </Button>
          </div>
        </form>
      </Modal>

      <NotificationModal
        open={successModalIsVisible}
        type="success"
        highlightedTitle="Well Done"
        title="You've successfully added a new card"
        onClose={handleClose}
        acceptLabel="Done"
        onAcceptClick={handleClose}
      />
    </>
  );
};

export default AddPaymentModal;
